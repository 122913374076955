import * as React from "react";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import ReactGA from "react-ga4";
import { ImageDisplay } from "./ImageDisplay";
import { photosList } from "./constants/constants";

const Container = styled("div")({
  flexGrow: 1,
  padding: "20px", // Adjust padding as needed
});

const ImageContainer = styled("div")(({ theme }) => ({
  position: "relative", // Needed for hover overlay
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden", // Hide overflowing parts of the image
  aspectRatio: "1/1", // 1:1 aspect ratio for square images
  margin: "auto", // Auto margins to center images horizontally and provide vertical spacing
  "&:hover img": {
    opacity: 1, // Make the image opaque on hover
    transform: "scale(1.1)", // Scale up on hover
    transition: "transform 0.3s ease, opacity 0.3s ease", // Smooth transition
  },
}));

const StyledImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover", // Maintain aspect ratio and cover container
  cursor: "pointer", // Show pointer cursor on hover
  opacity: 0.5, // Make the image transparent by default
});

const Home = () => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState<string>("");

  const openImage = (imageUrl: string, imageName: string) => {
    setSelectedImage(imageUrl);
    setModalOpen(true);

    ReactGA.event({
      category: "ImagePreview",
      action: `image_${imageName}`,
      label: imageName,
      value: 1,
    });
  };

  const closeImage = () => {
    setModalOpen(false);
  };

  return (
    <Container>
      <Grid container spacing={{ xs: 6, sm: 8, md: 10, lg: 12 }}>
        {/* Increase spacing here */}
        {photosList.map((p, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <ImageContainer onClick={() => openImage(`/media/gallery/${p}`, p)}>
              <StyledImage src={`/media/gallery/${p}`} alt={`Image ${p}`} />
            </ImageContainer>
          </Grid>
        ))}
      </Grid>
      <ImageDisplay open={modalOpen} onClose={closeImage} src={selectedImage} />
    </Container>
  );
};

export { Home };
