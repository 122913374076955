import React, { useEffect, useState } from "react";
import { Dialog, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

interface ImageDisplayProps {
  src: string;
  alt?: string;
  open: boolean;
  onClose: () => void;
}

export const ImageDisplay: React.FC<ImageDisplayProps> = ({
  src,
  alt = "",
  open,
  onClose,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => setZoomLevel(1), [src]);

  const toggleZoom = () => {
    setZoomLevel(zoomLevel === 1 ? 2 : 1);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false} // No max width, to fit the image size exactly
      PaperProps={{
        sx: {
          backgroundColor: "transparent", // Make the modal background transparent
          boxShadow: "none", // Remove the default shadow
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        {/* Close button */}
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
            color: "#fff",
            overflow: "auto",
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        {/* Zoom in/out button */}
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            left: 8,
            zIndex: 1,
            color: "#fff",
          }}
          onClick={toggleZoom}
        >
          {zoomLevel === 1 ? <ZoomInIcon /> : <ZoomOutIcon />}
        </IconButton>

        {/* Image */}
        <Box
          component="img"
          src={src}
          alt={alt}
          sx={{
            display: "block",
            maxWidth: "100%",
            maxHeight: "100vh", // Image will never exceed the screen height
            margin: "0 auto",
            transform: `scale(${zoomLevel})`, // Apply zoom
            transition: "transform 0.3s ease", // Smooth zoom transition
          }}
        />
      </Box>
    </Dialog>
  );
};
