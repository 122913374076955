export const fullName = "Bob the Bladesmith";
export const emailAddress = "bobthebladesmith@gmail.com";
export const ytAboutUsUrl = "https://www.youtube.com/embed/dQw4w9WgXcQ";
export const ytPressDemoUrl = "https://www.youtube.com/embed/0vDwvFRIjCc";

export const baseMediaUrl = "/media";
export const galleryUrl = `${baseMediaUrl}/gallery`;
export const papersUrl = `${baseMediaUrl}/papers`;

export const forgingHydraulicPressUrl = `${papersUrl}/forging_press.pdf`;

export const photosList = [
  "bunka_1.jpg",
  "gyuto_1.jpg",
  "gyuto_2.jpg",
  "nakiri_1.jpg",
  "petty_1.jpg",
  "two_knives.jpg",
];
