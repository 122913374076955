import * as React from "react";
import { PropsWithChildren, useRef, useEffect } from "react";
import { styled } from "@mui/system";
import { Footer } from "./Footer";
import { Header } from "./Header";

const LayoutContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  position: "relative", // Ensure relative positioning for overlay content
  overflow: "hidden", // Prevents scrollbars from appearing
});

const VideoBackground = styled("video")({
  position: "fixed", // Fixed position to keep it in place
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  objectFit: "cover",
  zIndex: -1, // Behind everything else
});

const Content = styled("div")({
  zIndex: 1, // Ensure content is above the video background
  textAlign: "center",
  color: "white",
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Optional: Text shadow for better readability
});

const FooterContainer = styled("footer")({
  position: "fixed", // Fixed position to keep it at the bottom
  bottom: 0,
  width: "100%",
  zIndex: 1, // Above the video background, but below content
});

export const Layout = ({ children }: PropsWithChildren): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <LayoutContainer>
      <VideoBackground ref={videoRef} loop autoPlay muted>
        <source src="/media/flames.mp4" type="video/mp4" />
        {/* Add additional source tags for other video formats like WebM or Ogg */}
        Your browser does not support the video tag.
      </VideoBackground>
      <Header />
      <Content>{children}</Content>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </LayoutContainer>
  );
};
