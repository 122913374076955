// Header.tsx
import * as React from "react";
import { styled } from "@mui/system";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { fullName } from "./constants/constants";

// Import the deer.jfif icon from the static folder
import deerIcon from "./assets/deer.png";

const StyledAppBar = styled(AppBar)({
  backgroundColor: "#000", // Black background color
  position: "fixed", // Fixed position to keep it at the top
  top: 0,
  left: 0,
  right: 0,
});

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 20px", // Adjust horizontal padding as needed
});

const StyledTypography = styled(Typography)({
  display: "flex",
  alignItems: "center",
  "& img": {
    height: "64px", // Adjust the height of the icon
    width: "auto", // Maintain aspect ratio
    marginRight: "8px", // Add margin between icon and text
    cursor: "pointer", // Show pointer cursor on hover
  },
});

const NavLink = styled(Link)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: "#fff", // White text color
  textDecoration: "none", // Remove underline
  "&:hover": {
    textDecoration: "underline", // Underline on hover
  },
}));

const Header = () => {
  return (
    <StyledAppBar>
      <StyledToolbar>
        <StyledTypography variant="h6">
          <NavLink to="/">
            <img src={deerIcon} alt="Deer Icon" />
          </NavLink>
          {fullName}
        </StyledTypography>
        <div>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/technical">Technical Papers</NavLink>
          <NavLink to="/contact">Contact</NavLink>
        </div>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export { Header };
