import * as React from "react";
import { Link, Typography } from "@mui/material";
import { fullName } from "./constants/constants";

export const Copyright = () => {
  return (
    <Typography variant="body2" align="center">
      {"Copyright © "}
      <Link color="inherit">{fullName}</Link> {new Date().getFullYear()}.
    </Typography>
  );
};
