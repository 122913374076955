import * as React from "react";
import { styled } from "@mui/system";

export const VideoContainer = styled("div")({
  marginTop: "20px",
  width: "100%", // Set to full width
  maxWidth: "1200px", // Increased max width
  minWidth: "600px", // Increased max width
  position: "relative",
  paddingBottom: "56.25%", // Maintain 16:9 aspect ratio
  height: 0,
});

export const Iframe = styled("iframe")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  border: "none",
});
