// About.tsx
import * as React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  forgingHydraulicPressUrl,
  ytPressDemoUrl,
} from "./constants/constants"; // Ensure you have the correct URL for the PDF
import { Iframe, VideoContainer } from "./VideoContainer";

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  minHeight: "100vh",
  backgroundColor: "transparent",
  paddingTop: "10vh",
});

const Content = styled("div")({
  textAlign: "center",
});

const PdfContainer = styled("div")({
  marginTop: "20px",
  width: "100%", // Set to full width
  maxWidth: "1500px", // Increased max width
  minWidth: "1200px", // Increased min width
  position: "relative",
  height: "90vh", // Adjust height to fit your needs
  overflow: "auto", // Hide scrollbars
});

const PdfIframe = styled("iframe")({
  width: "100%",
  height: "100%",
  border: "none",
  // overflow: "hidden", // Hide scrollbars within iframe
});

export const TechnicalPapers = () => {
  return (
    <Container>
      <Content>
        <Typography variant="h4" gutterBottom>
          Technical Papers
        </Typography>
        <Typography variant="body1" gutterBottom>
          Hydraulic Forging Press
        </Typography>
        <PdfContainer>
          <PdfIframe
            src={forgingHydraulicPressUrl + "#view=FitH&navpanes=1"} // URL of the PDF file
            // typeof="application/pdf"
            title="PDF Document"
          />
        </PdfContainer>
        <VideoContainer>
          <Iframe
            src={ytPressDemoUrl}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Hydraulic Forging Press Video"
          />
        </VideoContainer>
      </Content>
    </Container>
  );
};
