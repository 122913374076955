// App.tsx
import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Box from "@mui/material/Box";
import { Layout } from "./Layout";
import { Home } from "./Home";
import { About } from "./About";
import { TechnicalPapers } from "./TechnicalPapers";
import { Contact } from "./Contact";
import "./App.css";
import { usePageTracking } from "./metrics/pageTracking";

export const App = () => {
  return (
    <Router>
      <Layout>
        <Box sx={{ my: 4 }}>
          <AppRoutes />
        </Box>
      </Layout>
    </Router>
  );
};

const AppRoutes = () => {
  usePageTracking();

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/technical" element={<TechnicalPapers />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
};
