// About.tsx
import * as React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "transparent",
  paddingTop: "10vh",
});

const Content = styled("div")({
  textAlign: "center",
});

export const About = () => {
  return (
    <Container>
      <Content>
        <Typography variant="h4" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          About Section Coming Soon
        </Typography>
        {/* <VideoContainer>
          <Iframe
            src={ytAboutUsUrl}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video"
          />
        </VideoContainer> */}
      </Content>
    </Container>
  );
};

export default About;
