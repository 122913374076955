// Contact.tsx
import * as React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { emailAddress } from "./constants/constants";

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "transparent", // Make background transparent
  paddingTop: "20vh", // Adjust padding to position content closer to the top
});

const Content = styled("div")({
  textAlign: "center",
});

export const Contact = () => {
  return (
    <Container>
      <Content>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1">
          You can reach us at{" "}
          <Link href="mailto:your-email@example.com" underline="hover">
            {emailAddress}
          </Link>
        </Typography>
      </Content>
    </Container>
  );
};
